/// Mixin to place items on a circle
/// @author Kitty Giraudel
/// @author Ana Tudor
/// @param {Integer} $item-count - Number of items on the circle
/// @param {Length} $circle-size - Large circle size
/// @param {Length} $item-size - Single item size
/// https://css-tricks.com/snippets/sass/placing-items-circle/
@mixin on-circle($item-count, $circle-size, $item-size) {
    position: relative;
    width: $circle-size;
    height: $circle-size;
    padding: 0;
    border-radius: 50%;
    list-style: none;

    > .item-on-circle {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: $item-size;
        height: $item-size;
        margin: -(calc($item-size / 2));

        $angle: calc(360 / $item-count);
        $rot: 0;

        @for $i from 1 through $item-count {
            &:nth-of-type(#{$i}) {
                transform: rotate($rot * 1deg)
                    translate(calc($circle-size / 2))
                    rotate($rot * -1deg);
            }

            $rot: $rot + $angle;
        }
    }
}

.circle-container-sm {
    @include on-circle($item-count: 18, $circle-size: 7.5em, $item-size: 0.5em);
}

.circle-container-md {
    @include on-circle($item-count: 18, $circle-size: 12em, $item-size: 0.5em);
}

.circle-container-lg {
    @include on-circle($item-count: 18, $circle-size: 25em, $item-size: 0.5em);
}

.item-on-circle {
    border-radius: 50%;
    background-color: white;
}

@keyframes rotate {
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes expand {
    10% {
        height: 0.75rem;
        width: 0.75rem;
        border: 1px solid white;
        background-color: var(--primary);
    }
    20% {
        height: 0.5rem;
        width: 0.5rem;
        border: none;
        background-color: white;
    }
}

.expanding {
    animation-name: expand;
    animation-duration: 4.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
