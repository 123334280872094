$enable-responsive-font-sizes: true;
$primary: #000000 !default;
$danger: #f03d3e;
$success: #1bb55c;
$severe-warning: #ff7300;
$dark-grey: #727272;
$mid-grey: #a3a3a3;
$light-grey: #f8f8f8;
$primary-btn: $primary !default;
$brand-secondary: #ffffff !default;
$brand-tertiary: #001489 !default;
$brand-other: #00ab84 !default;

$nav-pills-border-radius: 20px;
$btn-border-radius: 20px;
$link-color: $primary;

// Set in line with chakra's values, to be deleted when we remove bootstrap
$zindex-modal: 1500;
$zindex-tooltip: 1800;

@use 'three-dots' with (
    $dot-color: $primary,
);

@font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Telegraf';
    src: local('Telegraf-Regular'), url(./fonts/PPTelegraf-Regular.woff2);
}

@import 'bootstrap';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

p a,
.btn-link {
    text-decoration: underline;
}

a:hover,
.btn-link:hover {
    color: inherit;
    text-decoration: none;
}

body {
    font-family: Roboto, serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
thead {
    font-family: 'Telegraf', serif;
}

:root {
    --dark-grey: #{$dark-grey};
    --mid-grey: #{$mid-grey};
    --light-grey: #{$light-grey};
    --brand-secondary: #{$brand-secondary};
    --brand-tertiary: #{$brand-tertiary};
    --brand-other: #{$brand-other};
}

.btn {
    padding: 0.6rem 1.2rem;
    border-radius: 20px;
}

.btn-sm {
    padding: 0.35rem 0.7rem;
}

.btn-lg {
    padding: 0.7rem 1.4rem;
}

.btn-primary:disabled {
    background-color: adjust-color(
        $primary-btn,
        $saturation: -60%,
        $lightness: 40%
    ) !important;
    border-color: adjust-color(
        $primary-btn,
        $saturation: -60%,
        $lightness: 40%
    ) !important;
}

.form-label {
    margin-bottom: 0.25rem;
}

.btn-brand {
    @extend .btn;
}

.btn-brand-secondary {
    @include button-variant($brand-secondary, darken($brand-secondary, 7.5%));
}

.btn-severe-warning {
    @include button-variant($severe-warning, $severe-warning);
}

.btn-brand-outline-secondary {
    @include button-outline-variant($brand-secondary);
}

.btn-brand-light {
    @include button-variant(rgba(white, 0), rgba(white, 0));
    box-shadow: none !important;
}

.border-bottom {
    border-bottom-width: 2px !important;
}
.border-top {
    border-top-width: 2px !important;
}
.border-left {
    border-left-width: 2px !important;
}
.border-right {
    border-right-width: 2px !important;
}
.border {
    border-width: 2px !important;
}

.container-16-9 {
    position: relative;
    padding-top: 56.25%;
}

.element-16-9 {
    position: absolute;
    top: 0;
    left: 0;
}

.bg-black {
    background-color: black !important;
}

.border-3 {
    border-width: 3px !important;
}

.clickable-container {
    cursor: pointer;
    background-color: transparent;
}

.clickable-container:hover,
.clickable-container:active {
    background-color: var(--light);
}

hr {
    border-width: 3px;
}

.modal-content {
    border-radius: 20px;
}

.mw-800-modal-dialog {
    max-width: 800px;
    margin: 1rem auto;
}

.mw-600-modal-dialog {
    max-width: 600px;
    margin: 1rem auto;
}

.nested-modals .modal-dialog > .modal-content {
    background-color: transparent;
    border: none;
}

.tooltip-inner {
    background-color: var(--secondary) !important;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--secondary) !important;
}
.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: var(--secondary) !important;
}
.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
    border-left-color: var(--secondary) !important;
}
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
    border-right-color: var(--secondary) !important;
}

video:focus {
    outline: none;
}

audio:focus {
    outline: none;
}

.min-h-0 {
    min-height: 0;
}

.marking-section-divider {
    background-color: #efefef;
}

.marking-section-divider[role='button'] {
    cursor: pointer;
}

.marking-section-divider[role='button']:hover {
    background-color: #e5e5e5;
}

.expander-chevron {
    transition-duration: 0.4s;
    transition-property: transform;
}

.flip {
    transform: rotate(-180deg);
}

.mirror {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.dropdown-toggle.hide-arrow::after,
.dropdown-toggle.hide-arrow::before {
    display: none !important;
}

.dropdown-item:hover,
.dropdown-item:active,
.dropdown-item.active {
    font-weight: bold;
    color: initial !important;
}

.dropdown-item:active,
.dropdown-item.active {
    background-color: initial !important;
}

.nav-link {
    font-weight: 500;
    text-decoration: none;
    margin-right: 1rem;
}

.nav-link:last-of-type {
    margin-right: 0;
}

a.nav-link,
a.dropdown-item {
    text-decoration: none;
}

// Hides the implicit password reveal button in microsoft edge
::-ms-reveal {
    display: none;
}

.interaction-toggle-btn {
    background: white;
    width: 50px;
    margin-right: 5px;
}

.btn:focus {
    box-shadow: none;
}
